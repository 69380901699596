import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../../components/seo/seo";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import Button from "../../../components/custom-widgets/button";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const PPPForgivenessFaq = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/small-business/ppp-forgiveness-faq/hero-ppp-portal-02232023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const title = "PPP Loan Forgiveness Application (2020 and 2021)";
  const description = "Apply for PPP (paycheck protection) loan Forgiveness at WaFd Bank.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/small-business/ppp-forgiveness"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-ppp-portal-02232023.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "ppp-faq-hero",
    ...getHeroImgVariables(imgData),
    altText: 'Business owner taping sign on window saying "We are open keep social distance"',
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "WaFd Bank's PPP Loan Forgiveness Portal"
          }
        },
        {
          id: 2,
          button: {
            id: "ppp-faq-hero-cta",
            text: "Start Application",
            url: "https://psomaster-16fa5354b62-171164-1713bd41511.force.com/",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/small-business",
      title: "Small Business Banking"
    },
    {
      id: 3,
      active: true,
      title: "PPP Loan Forgiveness Application"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>We are happy to announce that WaFd Bank's PPP Loan Forgiveness Portal is now ready for you.</h1>
        <p>Before you begin, please gather your documents and note:</p>
        <ul>
          <li>The system will guide you through questions to complete a forgiveness application;</li>
          <li>The portal will allow you to choose either an 8 or 24-week cover period;</li>
          <li>
            We will be able to submit both the 3508EZ and 3508 with Schedule A to the SBA on your behalf – your
            responses will help determine which one is used;
          </li>
          <li>
            Please use the same email address you used to apply for that specific PPP loan, and note that we will need
            to generate a new password for you;
          </li>
          <li>
            After you reset your password and login, please click on the menu item on the left side of the screen named
            "Marketplace" and then click on the "apply" button for the Paycheck Protection Program Forgiveness;
          </li>
          <li>You may save and return your forgiveness application during the process;</li>
          <li>
            You'll find a "Help" box on each page of the application that provides important information and tips; and
          </li>
          <li>
            Bank statements, tax filings, payroll reports and other documents can support your forgiveness submission
            and will need to be uploaded to complete your application.
          </li>
        </ul>
        <Button
          type="anchor"
          containerClass="text-center"
          id="1"
          externalLink={true}
          showIcon={false}
          rel="noopener noreferrer"
          target="_blank"
          url="https://psomaster-16fa5354b62-171164-1713bd41511.force.com/"
          text="Start Application"
        />
      </section>
      <ContactInfo type="business" />
    </SecondaryLanding>
  );
};

export default PPPForgivenessFaq;
